import React, { useState } from "react";
import { Button, Checkbox, Chip, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Image, Input } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import axios from "axios";
import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import prices from "./country.json";

const Main = () => {
  const [tags, setTags] = useState([]);
  const [samplePrompts, setSamplePrompts] = useState([]);
  const [aspectRatio, setAspectRatios] = useState([]);
  const [negativeSamplePrompt, setNegativeSamplePrompt] = useState([]);

  const [isloading, setIsloading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [urlKey, setUrlKey] = useState([]);

  const [state, setState] = useState({
    // Array keys
    aspectRatioPresets: [],
    // availableSamplers: [],
    gallery: [],
    tags: [],
    sampleRefImage: [], // make it like gallery
    videoGallery: [
      "https://cdn.openai.com/sora/videos/monster-with-melting-candle.mp4",
      "https://cdn.openai.com/sora/videos/mitten-astronaut.mp4",
      "https://cdn.openai.com/sora/videos/big-sur.mp4",
    ],
    negativePromptPresets: [],
    samplePrompt: [],
    advanceSettingSection: [
      {
        label: "Line 1",
        textKey: "headingTextFlawless",
        fontStyleKey: "headingFontStyleFlawless",
        maxLength: 20,
      },
    ],
    reelTransitionPresets: [
      {
        key: "fade",
        name: "Fade",
      },
      {
        key: "dissolve",
        name: "Dissolve",
      },
      {
        key: "pixelize",
        name: "Pixelize",
      },
      {
        key: "distance",
        name: "Distance",
      },
      {
        key: "wipeleft",
        name: "Wipe Left",
      },
      {
        key: "circleclose",
        name: "Circle Close",
      },
    ],
    samplePromptStory: [
      {
        id: "story-maker_samplePrompt09",
        promptValue: "Directing traffic on the road.\\n walking on the streets.\\n [NC]Chasing a man on the street.\\n At the police station.",
        shouldPromptVisible: true,
      },
      {
        id: "story-maker_samplePrompt10",
        promptValue:
          "At home, he reads the newspaper. The headline grabs his attention'There is a treasure house in the forest.\\n He drives on the road, nearing the forest, determined to find the treasure.\\nAs he ventures deeper into the forest at night, a tiger suddenly appears. The tiger's mouth is open, and it looks very frightening.\\nTerrified, he starts running very fast through the forest, trying to escape the tiger.\\n While running, he spots a house in the forest. It's nighttime, and the house looks eerie. \\nSuddenly, he discovers the treasure house! Inside the house, he finds it filled with treasure. \\nOverjoyed, he starts laughing inside the house, realizing he has found the treasure.\\nFeeling safe and content, he carefully makes his way out of the forest, having achieved his goal.",
        shouldPromptVisible: true,
      },
      {
        id: "story-maker_samplePrompt11",
        promptValue:
          "at home, began to go to drawing \\nsitting alone on a park bench.\\nreading a book on a park bench.\\nA squirrel approaches, peeking over the bench. \\nlook around in the park. # She looks around and enjoys the beauty of nature.\\nleaf falls from the tree, landing on the sketchbook.\npicks up the leaf, examining its details closely.\\nThe brown squirrel appear.\\nShe is very happy to see the squirrel again\\nThe brown squirrel takes the cracker and scampers up a tree. # She gives the squirrel cracker",
        shouldPromptVisible: true,
      },
      {
        id: "story-maker_samplePrompt12",
        promptValue:
          "wake up in the bed.\\n have breakfast. \\nis on the road, go to the company,  close look. \\n work in the company. \\n laughing happily. \\n lying in bed at night",
        shouldPromptVisible: true,
      },
    ],
    sampleRefVideo: [
      "https://imagesai.appypie.com/videoImages/video3.mp4",
      "https://imagesai.appypie.com/videoImages/video1.mp4",
      "https://imagesai.appypie.com/videoImages/video2.mp4",
    ],
    upscaleFactors: [2, 4],
    storyMakerPrompt: [
      {
        key: "general",
        name: "General",
        value: "",
      },
    ],

    // String keys
    countryPriceDetails:{},
    para_1: "",
    para_2: "",
    heading: "",
    video: "",
    img_1: "",
    img_2: "",
    name: "",
    category: "",
    categoryName: "",
    checkpoint: "",
    key: "",
    version: "",
    // recommendedSampler: "",
    endPointRedirectionUrl: "",
    // author: "",
    // activationToken: "",
    interface: "stable-diffusion",
    aiFeature: "",
    url: "",
    negativePromptName: "",
    aspectRatioName: "",
    samplePromptID: "",
    // Boolean or null keys
    isStyle: false,
    // liveHosting: false,
    isMusicIcon: false,
    isNumberOfGeneration: false,
    isTrending: false,
    active: true,
    isStyleVideo: false,
    isPrompt: false,
    isStyleStory: false,
    isAspectRatio: false,
    isNegativePrompt: false,
    isInitialImage: false,
    isPulidStyle: false,
    isAdvanceSetting: false,
    isvideoGen: false,
    isPyramidStyle: false,
    isComingSoon: false,
    isExtraPrompt: false,
    isQuality: false,
    isAdvancePanormic: false,
    isAudioTime: false,
    isPhotoID: false,
    shouldPromptVisible: false,
    isEmojiStyle: false,
    isInitialVideo: false,
    isReelTransition: false,
    endPointRedirectionBtn: false,
    isStory: false,
    isRemoveBg: false,
    isPulidGen: false,
    isSamplePrompt: false,
    isMotionFrame: false,
    isActive: true,
    IMAGE_REFERENCE_URL: false,

    // Numeric or size-related keys
    recommendedSteps: 20,
    maxLength: 0,
    maxResolution: { width: 0, height: 0 },
    baseResolution: { width: 0, height: 0 },
    width: 0,
    height: 0,
    recommendedGuidance: 10,
    step1: 0,
  });

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlKey).then(() => {
      alert("URL copied to clipboard!");
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value, // Dynamically update the key based on the input's "name" attribute
    }));
  };




    const handleInputPrice = (e, item) => {
      const value = e.target.value;
    
      setState((prevState) => ({
        ...prevState,
        countryPriceDetails: {
          ...prevState.countryPriceDetails,
          [item.countryName]: {
            id: prevState.key || "", // Dynamically use the model key
            countryName: item.countryName,
            marginalPrice: value,
            currency: item.currency,
            countryCode: item.countryCode,
            currencySymbol: item.currencySymbol
          },
        },
      }));
    };
    

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: checked, // Update the Boolean value based on the checkbox's checked state
    }));
  };

  const handleDropdownselect = (e, dropdownname) => {
    setState({ ...state, categoryName: e?.anchorKey });
  };
  const handleDropdownIndex = (e, dropdownname) => {
    setState({ ...state, modelIndex: e?.anchorKey });
  };
  const handleClose = (tagToRemove, stateToRemove) => {
    if (stateToRemove === "samplePrompt") {
      setSamplePrompts(samplePrompts.filter((tag) => tag !== tagToRemove));
    } else if (stateToRemove === "aspectRatio") {
      setAspectRatios(aspectRatio.filter((tag) => tag !== tagToRemove));
    } else if (stateToRemove === "tag") {
      setTags(tags.filter((tag) => tag !== tagToRemove));
    } else if (stateToRemove === "negativeSamplePrompt") {
      setNegativeSamplePrompt(negativeSamplePrompt.filter((tag) => tag !== tagToRemove));
    } else if (stateToRemove === "gallery") {
      setState((prevState) => ({
        ...prevState,
        gallery: [
          ...prevState.gallery.filter((item) => item !== tagToRemove), // Remove the tag if it exists
        ],
      }));
    } else if (stateToRemove === "sampleRefImage") {
      setState((prevState) => ({
        ...prevState,
        sampleRefImage: [
          ...prevState.sampleRefImage.filter((item) => item !== tagToRemove), // Remove the tag if it exists
        ],
      }));
    }
  };
  const handleAddTag = (stateKey) => {
    if (stateKey === "tags") {
      setTags([...tags, state.modelTag]);
      setState({ ...state, modelTag: "" });
    } else if (stateKey === "samplePrompts") {
      setSamplePrompts([...samplePrompts, state.samplePrompt]);

      //  setSamplePrompts([
      //   ...samplePrompts,
      //   { id: state.samplePromptID, promptValue: state.samplePrompt, shouldPromptVisible: state?.isPrompt },
      // ]);

      setState({ ...state, samplePrompt: "" });
    } else if (stateKey === "aspectRatio") {
      setAspectRatios([
        ...aspectRatio,
        { name: state.aspectRatioName, width: state.aspectRatioWidth, height: state.aspectRatioHeight, key: state?.aspectRatioName?.toLowerCase() },
      ]);
      setState({ ...state, aspectRatioName: "", aspectRatioWidth: "", aspectRatioHeight: "" });
    } else if (stateKey === "negativeSamplePrompt") {
      setNegativeSamplePrompt([
        ...negativeSamplePrompt,
        { name: state.negativePromptName, value: state.negativePromptPresets, key: state?.negativePromptName?.toLowerCase() },
      ]);
      setState({ ...state, negativePromptPresets: "", negativePromptName: "" });
    }
  };

  const handleModelFile = async (e) => {
    const files = Array.from(e.target.files);

    const { name } = e.target;

    if (name === "gallery") {
      if (state.gallery.length + files.length > 5) {
        alert("You can upload a maximum of 5 images.");
        return;
      }

      const uploadedFiles = await Promise.all(
        files.map(async (file) => {
          const formdata = new FormData();
          formdata.append("image", file, file.name);
          formdata.append("userId", "00");

          const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };

          try {
            const response = await fetch("https://us-central1-appydesigne-24e6c.cloudfunctions.net/checkImageNsfwsFilter", requestOptions);
            const result = await response.json(); // Parse as JSON

            // Check if nsfw_detected is false before proceeding
            if (result.nsfw_detected === false && result.imageUrl) {
              return result.imageUrl;
            } else {
              console.warn("NSFW content detected, skipping image.");
              return null; // Skip if NSFW content is detected
            }
          } catch (error) {
            console.error("Error during NSFW check:", error);
            return null; // Skip the file if there's an error
          }
        })
      );

      // Filter out any failed or NSFW uploads
      const successfulUploads = uploadedFiles.filter((url) => url !== null);

      setState((prevState) => ({
        ...prevState,
        gallery: [...prevState.gallery, ...successfulUploads],
      }));
    }
    if (name === "sampleRefImage") {
      if (state.sampleRefImage.length + files.length > 5) {
        alert("You can upload a maximum of 5 images.");
        return;
      }

      const uploadedFiles = await Promise.all(
        files.map(async (file) => {
          const formdata = new FormData();
          formdata.append("image", file, file.name);
          formdata.append("userId", "00");

          const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
          };

          try {
            const response = await fetch("https://us-central1-appydesigne-24e6c.cloudfunctions.net/checkImageNsfwsFilter", requestOptions);
            const result = await response.json(); // Parse as JSON

            // Check if nsfw_detected is false before proceeding
            if (result.nsfw_detected === false && result.imageUrl) {
              return result.imageUrl;
            } else {
              console.warn("NSFW content detected, skipping image.");
              return null; // Skip if NSFW content is detected
            }
          } catch (error) {
            console.error("Error during NSFW check:", error);
            return null; // Skip the file if there's an error
          }
        })
      );

      // Filter out any failed or NSFW uploads
      const successfulUploadsref = uploadedFiles.filter((url) => url !== null);

      //  setUploadedRefImage((prevFiles) => [...prevFiles, ...successfulUploadsref]);

      setState((prevState) => ({
        ...prevState,
        sampleRefImage: [...prevState.sampleRefImage, ...successfulUploadsref],
      }));
    }
  };

  const BASE_URL = "https://us-central1-appydesigne-24e6c.cloudfunctions.net/dynamicModelApi";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const createInputData = () => {
    const input = {
      key: state.key,
      categoryName: state.categoryName,
      category: state.category,
      interface: state.interface || "stable-diffusion", // Defaulting to "stable-diffusion" if not available
      isTrending: state.isTrending,
      name: state.name,
      gallery: state.gallery, // Fallback to uploadedImage if imageGallery is null
      isPrompt: state.isPrompt,
      isNegativePrompt: state.isNegativePrompt,
      isInitialImage: state.isInitialImage,
      isNumberOfGeneration: state.isNumberOfGeneration,
      isAspectRatio: state.isAspectRatio,
      tags: tags,
      modelIndex: state.modelIndex,
      isRemoveBg: state.isRemoveBg,
      isMusicIcon: state.isMusicIcon,
      method: state.method, // Default to GET if not defined
      para_2: state.para_2,
      isStyle: state.isStyle,
      active: state.active,
      aspectRatioPresets: state.aspectRatioPresets,
      version: state.version,
      sampleRefImage: state.sampleRefImage,
      sampleRefVideo: state.sampleRefVideo,
      isStyleVideo: state.isStyleVideo,
      samplePromptStory: state.samplePromptStory,
      samplePrompt: state.samplePrompt,
      endPointRedirectionUrl: state.endPointRedirectionUrl,
      isStyleStory: state.isStyleStory,
      checkpoint: state.checkpoint,
      recommendedSteps: state.recommendedSteps,
      maxLength: state.maxLength,
      isPulidStyle: state.isPulidStyle,
      isAdvanceSetting: state.isAdvanceSetting,
      isvideoGen: state.isvideoGen,
      maxResolution: state.maxResolution,
      para_1: state.para_1,
      isPyramidStyle: state.isPyramidStyle,
      recommendedGuidance: state.recommendedGuidance,
      isComingSoon: state.isComingSoon,
      upscaleFactors: state.upscaleFactors,
      step1: state.step1,
      isExtraPrompt: state.isExtraPrompt,
      isQuality: state.isQuality,
      isAdvancePanormic: state.isAdvancePanormic,
      isAudioTime: state.isAudioTime,
      video: state.video,
      isPhotoID: state.isPhotoID,
      negativePromptPresets: state.negativePromptPresets,
      id: state.id,
      img_2: state.img_2,
      videoGallery: state.videoGallery,
      shouldPromptVisible: state.shouldPromptVisible,
      isEmojiStyle: state.isEmojiStyle,
      isInitialVideo: state.isInitialVideo,
      aiFeature: state.aiFeature,
      isReelTransition: state.isReelTransition,
      endPointRedirectionBtn: state.endPointRedirectionBtn,
      isStory: state.isStory,
      width: state.width,
      isActive: state.isActive,
      url: state.url,
      advanceSettingSection: state.advanceSettingSection,
      img_1: state.img_1,
      height: state.height,
      reelTransitionPresets: state.reelTransitionPresets,
      isPulidGen: state.isPulidGen,
      baseResolution: state.baseResolution,
      data: state.data,
      isSamplePrompt: state.isSamplePrompt,
      isMotionFrame: state.isMotionFrame,
      IMAGE_REFERENCE_URL: state.IMAGE_REFERENCE_URL,
      countryPriceDetails:state.countryPriceDetails
    };

    if (state.isAspectRatio) input.aspectRatioPresets = aspectRatio;
    if (state.isNegativePrompt) input.negativePromptPresets = negativeSamplePrompt;

    if (state.isPrompt) {
      //  input.isSamplePrompt = true;
      input.samplePrompt = samplePrompts;
    }

    return input;
  };

  // Common function to check required fields
  const validateFields = () => {
    return true;
  };

  const handleModelAdd = async () => {
    if (!validateFields()) return;
    setIsloading(true);
    const input = createInputData();

    try {
      const response = await axios.post(`${BASE_URL}/add-ai-model`, input, config);
      console.log("Model added successfully:", response.data);
      setUrlKey(`https://videotool.appypie.com/playground/${response.data.data.key}`);
      setIsloading(false);
      onOpen();
    } catch (error) {
      setIsloading(false);
      console.error("Error adding model:", error);

      // Show error toast if key already exists
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.error || "An error occurred while adding the model.");
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleLogout = () => {
    // Remove auth and loginTimestamp from localStorage
    localStorage.removeItem("auth");
    localStorage.removeItem("loginTimestamp");

    // Optionally, you can redirect to the login page after logout
    window.location.href = "/login"; // Or use `navigate("/login")` if using `useNavigate`
  };


  


  return (
    <div className="flex flex-col md:flex-row justify-between my-4">
      <ToastContainer />
      <div className="md:w-1/2 p-4 border border-gray-300 rounded-lg bg-gray-50">
        <h2 className="text-lg font-semibold mb-2">Live JSON Data</h2>
        <textarea
          className="w-full  h-[90vh] overflow-scroll p-2 border rounded-lg bg-gray-100 focus:outline-none"
          readOnly
          value={JSON.stringify(createInputData(), null, 2)}
        />
      </div>

      <div className="md:w-1/2 p-4 h-[95vh] overflow-scroll">
        {isloading ? (
          <>
            <div className="bg-gray-200 w-full h-full flex justify-center items-center">
              <div className="flex min-h-[100%] w-full items-center justify-center bg-gray-200">
                <div className="flex flex-col">
                  <h4 className="mb-8">Loading....</h4>

                  <div className="flex flex-row space-x-4">
                    <div
                      className="w-12 h-12 rounded-full animate-spin
                    border-2 border-dashed border-blue-500 border-t-transparent"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between">
              <h3 className="text-[20px] ">Current Interface: {state.interface}</h3>
              <div onClick={handleLogout}>
                <svg
                  className="h-8 w-8 text-red-500"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                  <path d="M7 12h14l-3 -3m0 6l3 -3" />
                </svg>
              </div>
            </div>

            <div>
              {/* Model Details */}
              <div>
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <p className="text-[20px] my-3">Model Index</p>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button variant="bordered" className="capitalize">
                          {state.modelIndex ? state.modelIndex : "Select Model Index"}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                        aria-label="Single selection example"
                        variant="flat"
                        disallowEmptySelection
                        selectionMode="single"
                        selectedKeys={state.categoryName}
                        onSelectionChange={handleDropdownIndex}
                      >
                        {/* {Array.from({ length: AllModel?.length+1 }, (_, index) => (
                              <DropdownItem key={index + 1}>{index + 1}</DropdownItem>
                            ))} */}
                        {/* <DropdownItem>1</DropdownItem>
                            <DropdownItem>2</DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="w-1/2">
                    <p className="text-[20px] my-3">Category Name</p>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button variant="bordered" className="capitalize">
                          {state.categoryName ? state.categoryName : "Select Category Name"}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        aria-label="Single selection example"
                        variant="flat"
                        disallowEmptySelection
                        selectionMode="single"
                        selectedKeys={state.categoryName}
                        onSelectionChange={handleDropdownselect}
                      >
                        <DropdownItem key="Image Models">Image Models</DropdownItem>
                        <DropdownItem key="Video Models">Video Models</DropdownItem>
                        <DropdownItem key="Sticker Models">Sticker Models</DropdownItem>
                        <DropdownItem key="3D Models">3D Models</DropdownItem>
                        <DropdownItem key="Utility Model">Utility Models</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div className="my-2">
                  <h3 className="my-4">Category</h3>
                  <Input
                    type="text"
                    label="Category"
                    variant="underlined"
                    id="category"
                    name="category"
                    value={state.category}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    className=""
                  />
                </div>
                {/* Model Image */}
                <div className="flex gap-2 mt-4">
                  <div>
                    <Button endContent={"*"} onClick={() => document.getElementById("file-upload").click()}>
                      Upload Model Image
                    </Button>
                    <input
                      id="file-upload"
                      type="file"
                      name="gallery"
                      multiple
                      accept="image/png, image/jpeg"
                      onChange={handleModelFile}
                      style={{ display: "none" }}
                    />
                    {state.gallery.length > 0 &&
                      state.gallery.map((img, index) => (
                        <div className="relative" key={index}>
                          <Image width={100} height={100} alt="Model Image" src={img} />
                          <span
                            className="crossbtn absolute top-0 right-0"
                            style={{ zIndex: 999 }}
                            onClick={() => {
                              handleClose(img, "gallery");
                            }}
                          >
                            {"X"}
                          </span>
                        </div>
                      ))}
                  </div>
                  {state.isInitialImage && (
                    <div>
                      <Button endContent={"*"} onClick={() => document.getElementById("file-upload2").click()}>
                        Upload sample Ref Image
                      </Button>
                      <input
                        id="file-upload2"
                        type="file"
                        name="sampleRefImage"
                        multiple
                        accept="image/png, image/jpeg"
                        onChange={handleModelFile}
                        style={{ display: "none" }}
                      />

                      {state.sampleRefImage.length > 0 &&
                        state.sampleRefImage.map((img, index) => (
                          <div className="relative" key={index}>
                            <Image width={100} height={100} alt="Model Image" src={img} />
                            <span
                              className="crossbtn absolute top-0 right-0"
                              style={{ zIndex: 999 }}
                              onClick={() => {
                                handleClose(img, "sampleRefImage");
                              }}
                            >
                              {"X"}
                            </span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>

                <div className="flex gap-4">
                  <div className="w-1/2">
                 
                    <h3 class="mt-3 text-[20px]">Tutorial Data </h3>
                    <div>
                      <Input
                        
                        type="text"
                        label="Heading"
                        variant="underlined"
                        id="heading"
                        name="heading"
                        value={state.heading}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    <div>
                      <Input
                        
                        type="text"
                        label="Para 1"
                        variant="underlined"
                        id="para_1"
                        name="para_1"
                        value={state.para_1}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    <div>
                      <Input
                        
                        type="text"
                        label="Para 2"
                        variant="underlined"
                        id="para_2"
                        name="para_2"
                        value={state.para_2}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                   
                    
                    <div>
                      <Input
                        
                        type="text"
                        label="Img 1"
                        variant="underlined"
                        id="img_1"
                        name="img_1"
                        value={state.img_1}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    <div>
                      <Input
                        
                        type="text"
                        label="Img 2"
                        variant="underlined"
                        id="img_2"
                        name="img_2"
                        value={state.img_2}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>

                    <div>
                      <Input
                        
                        type="text"
                        label="Video"
                        variant="underlined"
                        id="video"
                        name="video"
                        value={state.video}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                   
                  </div>
                  <div className="w-1/2">
                  <h3 class="mt-3 text-[20px]">Main Keys</h3>
                  <div>
                      <Input
                        
                        type="text"
                        label="Name"
                        variant="underlined"
                        id="name"
                        name="name"
                        value={state.name}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    <div>
                      <Input
                        
                        type="text"
                        label="Key"
                        variant="underlined"
                        id="key"
                        name="key"
                        value={state.key}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    {/* <div>
                      <Input
                        
                        type="text"
                        label="Version"
                        variant="underlined"
                        id="version"
                        name="version"
                        value={state.version}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div> */}
                    <div>
                      <Input
                        
                        type="text"
                        label="Interface"
                        variant="underlined"
                        id="interface"
                        name="interface"
                        value={state.interface}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    {/* <div>
                      <Input
                        
                        type="text"
                        label="Img URL"
                        variant="underlined"
                        id="imgurl"
                        name="imgurl"
                        value={state.imgurl}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div> */}

                    <div>
                      <Input
                        
                        type="text"
                        label="Checkpoint"
                        variant="underlined"
                        id="checkpoint"
                        name="checkpoint"
                        value={state.checkpoint}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    {/* <div>
                      <Input
                        
                        type="text"
                        label="Recommended Sampler"
                        variant="underlined"
                        id="recommendedSampler"
                        name="recommendedSampler"
                        value={state.recommendedSampler}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div> */}
                    <div>
                      <Input
                        
                        type="text"
                        label="End Point Redirection URL"
                        variant="underlined"
                        id="endPointRedirectionUrl"
                        name="endPointRedirectionUrl"
                        value={state.endPointRedirectionUrl}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    {/* <div>
                      <Input
                        
                        type="text"
                        label="Author"
                        variant="underlined"
                        id="author"
                        name="author"
                        value={state.author}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div> */}
                    {/* <div>
                      <Input
                        
                        type="text"
                        label="Activation Token"
                        variant="underlined"
                        id="activationToken"
                        name="activationToken"
                        value={state.activationToken}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div> */}

                    <div>
                      <Input
                        
                        type="text"
                        label="AI Feature"
                        variant="underlined"
                        id="aiFeature"
                        name="aiFeature"
                        value={state.aiFeature}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                    </div>
                    
                  </div>
                </div>
                {/* Tags */}
                <div className="mt-5">
                  <div className="flex gap-2">
                    <Input
                      type="text"
                      label="Tags"
                      variant="underlined"
                      id="modelTag"
                      name="modelTag"
                      value={state.modelTag}
                      onChange={(e) => {
                        handleInput(e);
                      }}
                      className=""
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        handleAddTag("tags");
                      }}
                    >
                      Add
                    </Button>
                  </div>

                  <div className="mt-4 flex gap-2 mb-4 flex-wrap">
                    {tags.map((tag, index) => (
                      <Chip key={index} onClose={() => handleClose(tag, "tag")} variant="flat">
                        {tag} 
                      </Chip>
                    ))}
                  </div>
                </div>
              </div>

              {/* Model UI Details */}
              <div>
                <h3 className="my-4 text-[25px]">Model UI Details</h3>

                <div className="flex gap-4">
                  {/* First Column */}
                  <div className="w-1/2">
                    <div>
                      <Checkbox id="isStyle" name="isStyle" type="checkbox" checked={state.isStyle || false} onChange={(e) => handleCheckbox(e)}>
                        Style
                      </Checkbox>
                    </div>
                    {/* <div>
                      <Checkbox
                        id="liveHosting"
                        name="liveHosting"
                        type="checkbox"
                        checked={state.liveHosting || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Live Hosting
                      </Checkbox>
                    </div> */}
                    <div>
                      <Checkbox
                        id="isMusicIcon"
                        name="isMusicIcon"
                        type="checkbox"
                        checked={state.isMusicIcon || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Music Icon
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isNumberOfGeneration"
                        name="isNumberOfGeneration"
                        type="checkbox"
                        checked={state.isNumberOfGeneration || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Number of Generations
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isTrending"
                        name="isTrending"
                        type="checkbox"
                        checked={state.isTrending || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Trending
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox id="active" name="active" type="checkbox" checked={state.active || false} onChange={(e) => handleCheckbox(e)}>
                        Is  Active
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isStyleVideo"
                        name="isStyleVideo"
                        type="checkbox"
                        checked={state.isStyleVideo || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Style Video
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox id="isPrompt" name="isPrompt" type="checkbox" checked={state.isPrompt || false} onChange={(e) => handleCheckbox(e)}>
                        Prompt
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isStyleStory"
                        name="isStyleStory"
                        type="checkbox"
                        checked={state.isStyleStory || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Style Story
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isAspectRatio"
                        name="isAspectRatio"
                        type="checkbox"
                        checked={state.isAspectRatio || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Aspect Ratio
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isNegativePrompt"
                        name="isNegativePrompt"
                        type="checkbox"
                        checked={state.isNegativePrompt || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Negative Prompt
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isInitialImage"
                        name="isInitialImage"
                        type="checkbox"
                        checked={state.isInitialImage || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Reference Image
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isPulidStyle"
                        name="isPulidStyle"
                        type="checkbox"
                        checked={state.isPulidStyle || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Pulid Style
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isAdvanceSetting"
                        name="isAdvanceSetting"
                        type="checkbox"
                        checked={state.isAdvanceSetting || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Advanced Setting
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isvideoGen"
                        name="isvideoGen"
                        type="checkbox"
                        checked={state.isvideoGen || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Video Generation
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="IMAGE_REFERENCE_URL"
                        name="IMAGE_REFERENCE_URL"
                        type="checkbox"
                        checked={state.IMAGE_REFERENCE_URL || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Image Reference Url
                      </Checkbox>
                    </div>
                  </div>

                  {/* Second Column */}
                  <div className="w-1/2">
                    <div>
                      <Checkbox
                        id="isPyramidStyle"
                        name="isPyramidStyle"
                        type="checkbox"
                        checked={state.isPyramidStyle || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Pyramid Style
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isComingSoon"
                        name="isComingSoon"
                        type="checkbox"
                        checked={state.isComingSoon || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Coming Soon
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isExtraPrompt"
                        name="isExtraPrompt"
                        type="checkbox"
                        checked={state.isExtraPrompt || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Extra Prompt
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isQuality"
                        name="isQuality"
                        type="checkbox"
                        checked={state.isQuality || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Quality
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isAdvancePanormic"
                        name="isAdvancePanormic"
                        type="checkbox"
                        checked={state.isAdvancePanormic || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Advanced Panoramic
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isAudioTime"
                        name="isAudioTime"
                        type="checkbox"
                        checked={state.isAudioTime || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Audio Time
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isPhotoID"
                        name="isPhotoID"
                        type="checkbox"
                        checked={state.isPhotoID || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Photo ID
                      </Checkbox>
                    </div>
                    {/* <div>
                      <Checkbox
                        id="shouldPromptVisible"
                        name="shouldPromptVisible"
                        type="checkbox"
                        checked={state.shouldPromptVisible || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Prompt Visibility
                      </Checkbox>
                    </div> */}
                    <div>
                      <Checkbox
                        id="isEmojiStyle"
                        name="isEmojiStyle"
                        type="checkbox"
                        checked={state.isEmojiStyle || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Emoji Style
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isInitialVideo"
                        name="isInitialVideo"
                        type="checkbox"
                        checked={state.isInitialVideo || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Initial Video
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isReelTransition"
                        name="isReelTransition"
                        type="checkbox"
                        checked={state.isReelTransition || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Reel Transition
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="endPointRedirectionBtn"
                        name="endPointRedirectionBtn"
                        type="checkbox"
                        checked={state.endPointRedirectionBtn || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Endpoint Redirection Button
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox id="isStory" name="isStory" type="checkbox" checked={state.isStory || false} onChange={(e) => handleCheckbox(e)}>
                        Story
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isRemoveBg"
                        name="isRemoveBg"
                        type="checkbox"
                        checked={state.isRemoveBg || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Remove Background
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isPulidGen"
                        name="isPulidGen"
                        type="checkbox"
                        checked={state.isPulidGen || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Pulid Generation
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isSamplePrompt"
                        name="isSamplePrompt"
                        type="checkbox"
                        checked={state.isSamplePrompt || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Sample Prompt
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        id="isMotionFrame"
                        name="isMotionFrame"
                        type="checkbox"
                        checked={state.isMotionFrame || false}
                        onChange={(e) => handleCheckbox(e)}
                      >
                        Motion Frame
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox id="isActive" name="isActive" type="checkbox" checked={state.isActive || false} onChange={(e) => handleCheckbox(e)}>
                        Active
                      </Checkbox>
                    </div>
                  </div>
                </div>

                <div>
                  {state.isPrompt && (
                    <div className="">
                      <div className="flex gap-2">
                        <Input
                          type="text"
                          label="Sample Prompt"
                          variant="underlined"
                          id="samplePrompt"
                          name="samplePrompt"
                          value={state.samplePrompt}
                          onChange={(e) => {
                            handleInput(e);
                          }}
                          className=""
                        />
                        {/* <Input
                          type="text"
                          label="ID"
                          variant="underlined"
                          id="samplePromptID"
                          name="samplePromptID"
                          value={state.samplePromptID}
                          onChange={(e) => {
                            handleInput(e);
                          }}
                          className=""
                        /> */}
                        {/* <div className="w-1/2">
                          <Checkbox
                            id="shouldPromptVisible"
                            name="shouldPromptVisible"
                            type="checkbox"
                            checked={state.shouldPromptVisible || false}
                            onChange={(e) => handleCheckbox(e)}
                          >
                            Should Prompt Visible
                          </Checkbox>
                        </div> */}
                      </div>
                      <Button
                        className="mt-4"
                        color="primary"
                        onClick={() => {
                          handleAddTag("samplePrompts");
                        }}
                      >
                        Add Prompt
                      </Button>

                      <div className="mt-4 flex gap-2 mb-4 flex-wrap">
                        {samplePrompts.map((samplePrompt, index) => (
                          <Chip key={index} onClose={() => handleClose(samplePrompt, "samplePrompt")} variant="flat">
                            {samplePrompt}
                          </Chip>
                        ))}
                      </div>
                    </div>
                  )}

                  {state.isNegativePrompt && (
                    <div className="flex gap-2">
                      <Input
                        type="text"
                        label="Negative Prompt Name"
                        variant="underlined"
                        id="negativePromptName"
                        name="negativePromptName"
                        value={state.negativePromptName}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                      <Input
                        type="text"
                        label="Negative Prompt Presets"
                        variant="underlined"
                        id="negativePromptPresets"
                        name="negativePromptPresets"
                        value={state.negativePromptPresets}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className=""
                      />
                      <Button
                        color="primary"
                        onClick={() => {
                          handleAddTag("negativeSamplePrompt");
                        }}
                      >
                        Add Preset
                      </Button>

                      {negativeSamplePrompt.map((nsamplePrompt, index) => (
                        <Chip key={index} onClose={() => handleClose(nsamplePrompt, "negativeSamplePrompt")} variant="flat">
                          {nsamplePrompt?.name}: {nsamplePrompt?.value}
                        </Chip>
                      ))}
                    </div>
                  )}

                  {state.isAspectRatio && (
                    <>
                      <div className="flex gap-2">
                        <Input
                          type="text"
                          label="Name"
                          variant="underlined"
                          id="aspectRatioName"
                          name="aspectRatioName"
                          value={state.aspectRatioName}
                          onChange={(e) => {
                            handleInput(e);
                          }}
                          className=""
                        />
                        <Input
                          type="number"
                          label="Width"
                          variant="underlined"
                          id="aspectRatioWidth"
                          name="aspectRatioWidth"
                          value={state.aspectRatioWidth}
                          onChange={(e) => {
                            handleInput(e);
                          }}
                          className=""
                        />
                        x
                        <Input
                          type="number"
                          label="Height"
                          variant="underlined"
                          id="aspectRatioHeight"
                          name="aspectRatioHeight"
                          value={state.aspectRatioHeight}
                          onChange={(e) => {
                            handleInput(e);
                          }}
                          className=""
                        />
                        <Button
                          color="primary"
                          onClick={() => {
                            handleAddTag("aspectRatio");
                          }}
                        >
                          Add Ratio
                        </Button>
                      </div>
                      <div className="mt-5 gap-3 flex flex-wrap">
                        {aspectRatio.map((ratio, index) => (
                          <Chip key={index} onClose={() => handleClose(ratio, "aspectRatio")} variant="flat">
                            <span>{ratio.name}</span>
                            <span>
                              {ratio.width}x{ratio.height}
                            </span>
                          </Chip>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="mt-5">
                <h4 className="text-[25px]">Generation Details</h4>
                <div>
                  <Input
                    
                    type="text"
                    label="method"
                    variant="underlined"
                    name="method"
                    value={state.method}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    className=""
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="">
                  <h1 className="my-4 text-[25px] ">Pricing Table</h1>

                  <table  className="w-full leading-normal px-4">
                    <thead>
                      <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Country
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                          {prices.country.map((item, index) => (
                             <tr key={index}>
                             <td>
                               <p>{item.countryName}</p>
                             </td>
                             <td>
                             <input type="number"
                           className=" border mt-2 text-gray-900 text-sm rounded-lg block w-full p-2" 
                           placeholder="12" 
                           value={
                             state.countryPriceDetails[item.countryName]?.marginalPrice || ""
                           }
                           onChange={(e) => handleInputPrice(e, item)}
                           />
                             </td>
                            
                           </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }} className="mb-4 mt-5">
              <Button
                color="warning"
                onClick={() => {
                  handleModelAdd();
                }}
              >
                Publish Model
              </Button>
            </div>
          </>
        )}
      </div>

      <Modal backdrop={"blur"} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Copy URL</ModalHeader>
              <ModalBody>
                <Input isReadOnly value={urlKey} label="Copy URL" variant="faded" className="mb-4" />
                <Button color="primary" variant="light" onPress={handleCopyUrl}>
                  Copy URL
                </Button>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Main;
